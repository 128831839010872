import React from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { HomeIcon, AreaIcon, TicketIcon, BookingsIcon, SettingsIcon, OrganizationsIcon, NotificationIcon, UsersIcon, GarageIcon, StatisticsIcon } from 'components/icons/icons';
import LoadingSkeleton from 'components/common/loadingSkeleton';
import useNotifications from 'context/notifications';

const LeftMenu = () => {

    const { isNotificationsLoading, notifications } = useNotifications();
    const location = useLocation();
    const { pathname } = location;
    const currentPage = pathname.split("/")[1];
    const isSupportBotEnabled = process.env.REACT_APP_SHOW_SUPPORT_BOT === 'true';

    if (!isNotificationsLoading) {
        return (
            <div className="md:flex hidden flex-col w-[272px] rounded-3xl p-6 ml-6 shadow-lg">
                <ul>
                    <LeftMenuItem name='Home' currentPage={currentPage} to=''><HomeIcon /></LeftMenuItem>
                    <LeftMenuItem name='Parking areas' currentPage={currentPage} to='parking-areas' hasNotifications={notifications.some(n => n.notificationTypeId === 2 && !n.isRead)}><AreaIcon /></LeftMenuItem>
                    <LeftMenuItem name='Control fees' currentPage={currentPage} to='control-fees' hasNotifications={notifications.some(n => n.notificationTypeId === 1 && !n.isRead)}><TicketIcon /></LeftMenuItem>
                    <LeftMenuItem name='Bookings' currentPage={currentPage} to='bookings'><BookingsIcon /></LeftMenuItem>
                    <LeftMenuItem name='Organizations' currentPage={currentPage} to='organizations'><OrganizationsIcon /></LeftMenuItem>
                    <LeftMenuItem name='Admins' currentPage={currentPage} to='admins'><UsersIcon /></LeftMenuItem>
                    {isSupportBotEnabled && (
                        <LeftMenuItem name='Support Bot' currentPage={currentPage} to='support-bot'><UsersIcon /></LeftMenuItem>
                    )}
                    <LeftMenuItem name='AirPasses' currentPage={currentPage} to='airpasses'>
                        <div className='w-6 flex'>
                            <GarageIcon />
                        </div>
                    </LeftMenuItem>
                    <LeftMenuItem name='KPI' currentPage={currentPage} to='kpi'><StatisticsIcon /></LeftMenuItem>
                    <LeftMenuItem name='Settings' currentPage={currentPage} to='settings'><SettingsIcon /></LeftMenuItem>
                </ul>
            </div>
        );
    } else {
        return (
            <LoadingSkeleton />
        );
    }
};

const LeftMenuItem = ({ name, to, currentPage, hasNotifications, children }) => {

    let className = 'flex flex-row items-center list-none text-base font-medium mb-3 pb-2 border-b-2';

    let active = false;

    if (!currentPage && to === '') {
        active = true;
    } else {
        active = currentPage === to
    }

    if (active) {
        className = `${className} border-airpark-green`;
    } else {
        className = `${className} border-white`;
    }

    return (
        <li className={className}>
            {children}
            <NavLink className="pl-2" to={to}>{name}</NavLink>
            {hasNotifications &&
                <NotificationIcon />
            }
        </li>
    )
}

export default LeftMenu;